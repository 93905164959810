<template>
  <div class="acts-view">
    <div class="noprint">
      <h4 class="my-4">
        {{
          $getVisibleNames("manual.acta_de_comite", true, "Actas de Comités")
        }}
        <!-- Actas de Comités -->
      </h4>
      <div class="acts-selectors-container mx-3">
        <div class="acts-selectors-column">
          <b-form-group
            label="Tipo de Acta:"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            label-class="acts-selectors-label"
            class="p-0"
          >
            <b-form-select
              class="acts-select"
              :options="act_types"
              v-model="form.selected_act_type"
              value-field="id"
              text-field="name"
              size="sm"
              :state="validateState('selected_act_type')"
              aria-describedby="select-act-feedback"
            >
            </b-form-select>
            <b-form-invalid-feedback id="select-act-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            :label="$getVisibleNames('mesh.campus', false, 'Sede') + ':'"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            label-class="acts-selectors-label"
            class="p-0"
          >
            <b-form-select
              class="acts-select"
              :options="userCampuses"
              v-model="form.selected_campus_id"
              value-field="id"
              text-field="name"
              size="sm"
              :state="validateState('selected_campus_id')"
              aria-describedby="select-campus-feedback"
            >
            </b-form-select>
            <b-form-invalid-feedback id="select-campus-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            :label="$getVisibleNames('mesh.faculty', false, 'Facultad') + ':'"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            label-class="acts-selectors-label"
            class="p-0"
          >
            <b-form-select
              class="acts-select"
              :options="filteredFaculties"
              v-model="form.selected_faculty_id"
              value-field="id"
              text-field="name"
              size="sm"
              :state="validateState('selected_faculty_id')"
              aria-describedby="select-faculty-feedback"
            >
            </b-form-select>
            <b-form-invalid-feedback id="select-faculty-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="acts-selectors-column">
          <b-form-group
            :label="`${$getVisibleNames('mesh.career', false, 'Programa')}:`"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            label-class="acts-selectors-label"
            class="p-0"
          >
            <b-form-select
              class="acts-select"
              :options="userCareers"
              v-model="form.selected_career_id"
              value-field="id"
              text-field="name"
              size="sm"
              :state="validateState('selected_career_id')"
              aria-describedby="select-career-feedback"
            >
            </b-form-select>
            <b-form-invalid-feedback id="select-career-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            :label="`${$getVisibleNames(
              'mesh.egressprofile',
              false,
              'Perfil de Egreso'
            )}:`"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            label-class="acts-selectors-label"
            class="p-0"
          >
            <!-- text-field="academic_degree" -->
            <!-- :options="userEgressProfiles" -->
            <b-form-select
              class="acts-select"
              v-model="form.selected_egress_profile_id"
              value-field="id"
              size="sm"
              :state="validateState('selected_egress_profile_id')"
              aria-describedby="select-profile-feedback"
            >
              <b-form-select-option
                v-for="egress_profile in userEgressProfiles"
                :key="egress_profile.id"
                :value="egress_profile.id"
              >
                {{ egress_profile.code }}
                <template v-if="egress_profile.code.trim() != ''"> - </template>
                {{ egress_profile.init_date | FormatToYear }}
                <template v-if="egress_profile.init_date"> - </template>
                {{ egress_profile.professional_title }}
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="select-profile-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- <b-form-group
            label="Nivel"
            label-size="sm"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            class="p-0 m-0"
          >
            <b-form-select
              :options="levels"
              v-model="form.selected_level"
              size="sm"
              :state="validateState('selected_level')"
              aria-describedby="select-level-feedback"
            >
            </b-form-select>
            <b-form-invalid-feedback id="select-level-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group> -->
          <b-form-group
            :label="$getVisibleNames('mesh.period', false, 'Período') + ':'"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            label-class="acts-selectors-label"
            class="p-0"
          >
            <b-form-select
              class="acts-select"
              value-field="id"
              v-model="form.selected_period_id"
              size="sm"
              :state="validateState('selected_period_id')"
              aria-describedby="select-period-feedback"
            >
              <b-form-select-option
                v-for="period in filtersPeriods"
                :key="period.id"
                :value="period.id"
              >
                {{ period.name }} de: {{ period.start_date | FormatToDate }} al
                {{ period.end_date | FormatToDate }}
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="select-period-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- <b-form-group
            :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
            label-size="sm"
            label-cols="0"
            label-cols-sm="1"
            label-cols-md="2"
            label-cols-lg="3"
            label-cols-xl="4"
            class="p-0 m-0"
          >
            <b-form-select
              :options="shifts"
              value-field="id"
              text-field="name"
              v-model="form.selected_shift_id"
              size="sm"
              :state="validateState('selected_shift_id')"
              aria-describedby="select-shift-feedback"
            >
            </b-form-select>
            <b-form-invalid-feedback id="select-shift-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group> -->
        </div>
      </div>
      <div class="mx-2">
        <b-button v-can="'cideu.add_act'" size="sm" @click="launchCreateAct"
          >Agregar Nueva Acta</b-button
        >
        <b-button size="sm" class="ml-3" @click="fetchActs"
          >Mostrar Actas</b-button
        >
      </div>
      <div class="mx-3">
        <ActList
          :Acts="filterActs"
          @open="slotOpenAct"
          @deleted="slotDeletedAct"
        ></ActList>
      </div>

      <!-- Modals -->
      <b-modal id="modal-create-act" hide-footer size="lg" title="Crear Acta">
        <ActForm
          :egress_profile_id="form.selected_egress_profile_id"
          :campus_id="form.selected_campus_id"
          :period_id="form.selected_period_id"
          :shift_id="form.selected_shift_id"
          :level="form.selected_level"
          :ActTypes="act_types"
          :act_type="form.selected_act_type"
          @created="slotCreatedAct"
        ></ActForm>
      </b-modal>
    </div>
    <div v-if="selectedAct" class="mx-3">
      <!-- :AspectTypes="filtered_aspect_types" -->
      <ActComponent
        :Act="selectedAct"
        :Aspects="aspects"
        :FulfillTypes="fulfill_types"
        :ActTypes="act_types"
        :identifierTypes="identifier_types"
        :SupportTypes="support_types"
        :allows_crud="allows_crud"
        @updated="slotUpdatedAct"
      ></ActComponent>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ActView",
  components: {
    ActList: () => import("./ActList"),
    ActForm: () => import("./ActForm"),
    ActComponent: () => import("./ActComponent"),
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        selected_campus_id: null,
        selected_faculty_id: null,
        selected_career_id: null,
        selected_egress_profile_id: null,
        selected_period_id: null,
        selected_level: null,
        selected_act_type: null,
        selected_shift_id: null,
      },
      acts: [],
      selected_act_id: null,
      // aspect_types: [],
      // filtered_aspect_types: [],
      aspects: [],
      fulfill_types: [],
      act_types: [],
      identifier_types: [],
      support_types: [],
      can_edit: false,
      enable_buttons: false,
    };
  },
  validations() {
    return {
      form: {
        selected_act_type: { required },
        selected_campus_id: { required },
        selected_faculty_id: { required },
        selected_career_id: { required },
        selected_egress_profile_id: { required },
        selected_period_id: { required },
        // selected_level: { required },
        selected_shift_id: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
      user: "getUser",
      sections: names.SECTIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      periods: names.PERIODS,
      shifts: names.SHIFTS,
      local_allows_crud: names.ALLOWS_CRUD,
    }),
    // userSections() {
    //   let list = [];
    //   this.user.sections_as_professor.forEach((section_id) => {
    //     const instance = this.sections.find((x) => x.id == section_id);
    //     if (instance) list.push(instance);
    //   });
    //   return list;
    // },
    allows_crud() {
      if (this.can_edit) {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
        if (this.can_edit && this.local_allows_crud)
          return this.local_allows_crud;
        else return false;
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    filtersPeriods() {
      if (this.egressProfile == null) return [];
      else
        return this.periods.filter(
          (x) => x.period_type == this.egressProfile.regime
        );
    },
    filterActs() {
      return this.acts
        .filter(
          (x) =>
            x.campus == this.form.selected_campus_id &&
            x.egress_profile == this.form.selected_egress_profile_id &&
            x.period == this.form.selected_period_id &&
            x.act_type == this.form.selected_act_type &&
            x.can_view_user_list.includes(this.user.id)
        )
        .sort(function (a, b) {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        });
    },
    // userMatters() {
    //   let list = [];
    //   this.userSections.forEach((section) => {
    //     const matter = this.egress_profiles_matters.find(
    //       (x) => x.id == section.egress_profile_matter
    //     );
    //     if (matter && list.findIndex((x) => x.id == matter.id) == -1)
    //       list.push(matter);
    //   });
    //   return list;
    // },
    userEgressProfiles() {
      let list = [];
      // if (this.user.is_superuser)
      list = [...this.egressProfiles];

      // this.userMatters.forEach((matter) => {
      //   const egress_profile = this.egressProfiles.find(
      //     (x) => x.id == matter.egress_profile
      //   );
      //   if (
      //     egress_profile &&
      //     list.findIndex((x) => x.id == egress_profile.id) == -1
      //   )
      //     list.push(egress_profile);
      // });
      if (this.form.selected_career_id) {
        return list.filter((x) => x.career == this.form.selected_career_id);
      }
      return list;
    },
    userCareers() {
      let list = [];
      // if (this.user.is_superuser)
      list = [...this.careers];
      // this.userEgressProfiles.forEach((egress_profile) => {
      //   const career = this.careers.find((x) => x.id == egress_profile.career);
      //   if (career && list.findIndex((x) => x.id == career.id) == -1)
      //     list.push(career);
      // });
      if (this.form.selected_faculty_id) {
        return list.filter((x) => x.faculty == this.form.selected_faculty_id);
      }
      return list;
    },
    userFaculties() {
      let list = [];
      // if (this.user.is_superuser)
      list = [...this.faculties];
      // this.userCareers.forEach((career) => {
      //   const faculty = this.faculties.find((x) => x.id == career.faculty);
      //   if (faculty && list.findIndex((x) => x.id == faculty.id) == -1)
      //     list.push(faculty);
      // });
      return list;
    },
    filteredFaculties() {
      let list = [...this.userFaculties];
      if (this.form.selected_campus_id) {
        this.userFaculties.forEach((faculty) => {
          let flag = false;
          faculty.campuses.forEach((campus_id) => {
            if (campus_id == this.form.selected_campus_id) flag = true;
          });
          if (!flag) {
            const index = list.findIndex((x) => x.id == faculty.id);
            if (index != -1) list.splice(index, 1);
          }
        });
      }
      return list;
    },
    userCampuses() {
      let list = [];
      // if (this.user.is_superuser)
      list = [...this.campuses];
      this.userEgressProfiles.forEach((egress_profile) => {
        egress_profile.campuses.forEach((campus_id) => {
          const campus = this.campuses.find((x) => x.id == campus_id);
          if (campus && list.findIndex((x) => x.id == campus.id) == -1)
            list.push(campus);
        });
      });
      return list;
    },
    carrer() {
      if (this.form.selected_career_id)
        return this.careers.find((x) => x.id == this.form.selected_career_id);
      return null;
    },
    egressProfile() {
      if (this.form.selected_egress_profile_id)
        return this.egressProfiles.find(
          (x) => x.id == this.form.selected_egress_profile_id
        );
      return null;
    },
    faculty() {
      if (this.form.selected_faculty_id)
        return this.faculties.find(
          (x) => x.id == this.form.selected_faculty_id
        );
      return null;
    },
    levels() {
      let min = 1;
      let max = 14;
      if (this.cycle) {
        min = this.cycle.init_semester;
        max = this.cycle.end_semester;
      }
      if (this.egressProfile) max = this.egressProfile.semester_amount;
      let list = [];
      for (let i = min; i <= max; i++) {
        list.push(i);
      }
      return list;
    },
    selectedAct() {
      if (!this.selected_act_id) return null;
      return this.acts.find((x) => x.id == this.selected_act_id);
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    launchCreateAct() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$bvModal.show("modal-create-act");
    },
    fetchActs() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (!this.form.selected_act_type) {
        this.$swal.fire("Debe seleccionar un Tipo de Acta.");
        return;
      }
      let filters = [];
      if (this.form.selected_act_type)
        filters.push(`act_type=${this.form.selected_act_type}`);
      if (this.form.selected_egress_profile_id)
        filters.push(`egress_profile=${this.form.selected_egress_profile_id}`);
      if (this.form.selected_campus_id)
        filters.push(`campus=${this.form.selected_campus_id}`);
      if (this.form.selected_period_id)
        filters.push(`period=${this.form.selected_period_id}`);
      // if (this.form.selected_level)
      //   filters.push(`level=${this.form.selected_level}`);
      if (this.form.selected_shift_id)
        filters.push(`shift=${this.form.selected_shift_id}`);
      let filter_url = filters.length > 0 ? "?" + filters.join("&") : "";
      this.$restful.Get(`/cideu/act/${filter_url}`).then((response) => {
        this.acts = response;
      });
    },
    // fetchAspectTypes() {
    //   if (!this.form.selected_act_type) return;
    //   this.$restful.Get(`/cideu/aspect-types/`).then((response) => {
    //     this.aspect_types = response;
    //     this.filtered_aspect_types = response;
    //   });
    // },
    fetchAspects() {
      this.$restful.Get("/cideu/aspect/").then((response) => {
        this.aspects = response;
      });
    },
    fetchFulfillTypes() {
      this.$restful.Get("/cideu/fulfill-types/").then((response) => {
        this.fulfill_types = response;
      });
    },
    fetchActTypes() {
      this.$restful.Get("/cideu/act-type/").then((response) => {
        this.act_types = response;
        this.form.selected_act_type = response[0].id;
      });
    },
    fetchSuportTypes() {
      this.$restful.Get("/cideu/support-types/").then((response) => {
        this.support_types = response;
      });
    },
    slotCreatedAct(act) {
      this.acts.push(act);
      this.fetchActs();
      this.$bvModal.hide("modal-create-act");
    },
    slotOpenAct(act_id) {
      this.selected_act_id = null;
      setTimeout(() => {
        this.selected_act_id = act_id;
      }, 1000);
    },
    slotUpdatedAct(act) {
      const index = this.acts.findIndex((x) => x.id == act.id);
      if (index != -1) {
        this.acts.splice(index, 1, act);
      }
    },
    slotDeletedAct(act_id) {
      const index = this.acts.findIndex((x) => x.id == act_id);
      if (index != -1) {
        this.acts.splice(index, 1);
      }
    },
  },
  watch: {
    userEgressProfiles() {
      if (this.userEgressProfiles.length > 0) {
        this.form.selected_egress_profile_id = this.userEgressProfiles[0].id;
        this.form.selected_career_id = this.userEgressProfiles[0].career;
        if (this.egressProfile.campuses.length > 0)
          this.form.selected_campus_id = this.egressProfile.campuses[0];
      }
    },
    carrer() {
      if (this.carrer) this.form.selected_faculty_id = this.carrer.faculty;
    },
    selectedAct() {
      if (this.selectedAct) {
        if (
          this.selectedAct.created_by == this.$store.getters.getUser.id ||
          this.selectedAct.can_edit_user_list.includes(
            this.$store.getters.getUser.id
          )
        )
          this.can_edit = true;
        else this.can_edit = false;
      }
    },
    // "form.selected_act_type"() {
    //   if (this.form.selected_act_type) {
    //     this.filtered_aspect_types = this.aspect_types.filter(
    //       (x) => x.act_type == this.form.selected_act_type
    //     );
    //     this.acts = [];
    //   }
    // },
    "form.selected_egress_profile_id"() {
      if (this.filtersPeriods.length > 0) {
        this.form.selected_period_id = this.filtersPeriods[0].id;
      }
    },
    "form.selected_career_id"() {
      if (this.carrer && this.carrer.egress_profiles.length > 0) {
        this.form.selected_egress_profile_id = this.carrer.egress_profiles[0];
        this.form.selected_period_id = this.periods
          .filter(
            (x) =>
              x.period_type ==
                (
                  this.egressProfiles.find(
                    (x) => x.id == this.carrer.egress_profiles[0]
                  ) || {}
                ).regime || null
          )
          .map((x) => x.id)[0];
      } else {
        this.form.selected_egress_profile_id = null;
        this.form.selected_period_id = null;
      }
    },
    "form.selected_faculty_id"() {
      if (this.userCareers.length > 0)
        this.form.selected_career_id = this.userCareers[0].id;
      else this.form.selected_career_id = null;
    },
  },
  created() {
    this.$restful.Get(`/common/identifer-type/`).then((response) => {
      this.identifier_types = response;
    });
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    // this.user.sections_as_professor.forEach((section_id) => {
    //   this.$store.dispatch(names.FETCH_SECTION, section_id).then((section) => {
    // this.form.selected_level = section.plan_level;
    // this.$store.dispatch(
    //   names.FETCH_EGRESS_PROFILE_MATTER,
    //   section.egress_profile_matter
    // );
    // .then((matter) => {
    //   this.$store
    //     .dispatch(names.FETCH_EGRESS_PROFILE, matter.egress_profile)
    //     .then((egress_profile) => {
    //       this.$store
    //         .dispatch(names.FETCH_CAREER, egress_profile.career)
    //         .then((career) => {
    //           this.$store.dispatch(names.FETCH_FACULTY, career.faculty);
    //         });
    //       // egress_profile.campuses.forEach((campus_id) => {
    //       //   this.$store.dispatch(names.FETCH_CAMPUS, campus_id);
    //       // });
    //     });
    // });
    // });
    // });
    this.$store.dispatch(names.FETCH_PERIODS);
    // .then((periods) => {
    //   if (periods.length > 0)
    //     this.form.selected_period_id = periods[periods.length - 1].id;
    // });
    this.$store.dispatch(names.FETCH_SHIFTS).then((shift) => {
      if (shift.length > 0) this.form.selected_shift_id = shift[0].id;
    });
    this.fetchAspects();
    this.fetchFulfillTypes();
    this.fetchActTypes();
    this.fetchSuportTypes();
    // this.fetchAspectTypes();
    // if (this.$store.getters.getInstitution.actual_period)
    //   this.form.selected_period_id =
    //     this.$store.getters.getInstitution.actual_period.id;
  },
};
</script>

<style scoped>
.acts-selectors-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.acts-selectors-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: right;
}
.acts-selectors-column >>> .acts-selectors-label {
  padding-top: 0.25rem !important;
}
.acts-select {
  font-size: 1rem !important;
}
@media (max-width: 991px) {
  .acts-selectors-container {
    display: block;
  }
  .acts-selectors-column {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .acts-selectors-column {
    display: block !important;
    text-align: center !important;
    width: 100%;
  }
}
</style>